<template>
  <div class="p-3">
    <div class="row">
      <div class="col-xl-9 col-lg-9 col-md-9 col-12">
        <appProgressSteps :propsData="gettStepId"></appProgressSteps>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-12">
        <div class="card h-100">
          <div class="card-body pb-0">need somting for marketing</div>
        </div>
      </div>
    </div>

    <section v-if="gettStepId == 1">
      <appStep1></appStep1>
    </section>
    <section v-if="gettStepId == 2">
      <appStep2></appStep2>
    </section>
    <section v-if="gettStepId == 3">
      <appStep3></appStep3>
    </section>
    <section v-if="gettStepId == 4">
      <appStep4></appStep4>
    </section>
    <section v-if="gettStepId == 5">
      <appStep5></appStep5>
    </section>
    <section v-if="gettStepId == 6">
      <appStep6></appStep6>
    </section>
  </div>
</template>
<script>
import appStep1 from "../../components/policeCheck/step1.vue";
import appStep2 from "../../components/policeCheck/step2.vue";
import appStep3 from "../../components/policeCheck/step3.vue";
import appStep4 from "../../components/policeCheck/step4.vue";
import appStep5 from "../../components/policeCheck/step5.vue";
import appStep6 from "../../components/policeCheck/step6.vue";
import appProgressSteps from "../../components/policeCheck/progressSteps.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    appStep1,
    appStep2,
    appStep3,
    appStep4,
    appStep5,
    appStep6,
    appProgressSteps,
  },
  computed: {
    ...mapGetters(["gettStepId"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["fetchStepId"]),
  },
  created() {
    this.fetchStepId(1);
  },
};
</script>